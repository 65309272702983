const Articles = [
	{
		id: 1,
		date: 'November 28th, 2023',
		dateCode: 20231128,
		title: 'Discover the Amazing Piers of New York City',
		tagline: `You're probably thinking "piers are just where boats dock, can't be that amazing". But the piers of New York City will surprise you with amazing rooftops, skyline views, architectural wonders and yes, even a beach!`,
		mainImageFilename: 'main.jpg',
		contentFilename: '1.md',
		linkText: `Once you've checked out these amazing piers, you will be in a great position to board a cruise. Check out these experiences:`,
		links: [
			{url: 'https://www.viator.com/tours/New-York-City/Circle-Line-Harbor-Lights-Night-Cruise/d687-2800HLC', imgSrc: 'https://media.tacdn.com/media/attractions-splice-spp-674x446/0a/0a/5d/d3.jpg', title: 'Circle Line: New York City Harbor Lights Cruise'},
			{url: 'https://www.viator.com/tours/New-York-City/Bateaux-New-York-Dinner-Cruise/d687-5042NYCBAT', imgSrc: 'https://media.tacdn.com/media/attractions-splice-spp-674x446/10/47/dc/f8.jpg', title: 'Bateaux New York Premier Dinner Cruise'},
			{url: 'https://www.viator.com/tours/New-York-City/Sunset-Sail-aboard-the-Schooner-Adirondack/d687-6288P21', imgSrc: 'https://media.tacdn.com/media/attractions-splice-spp-674x446/07/05/2a/28.jpg', title: 'New York Sunset Schooner Cruise on the Hudson River'},
		],
	},
	{
		id: 2,
		date: 'November 29th, 2023',
		dateCode: 20231129,
		title: 'Best Underrated Museums in New York City',
		tagline: `You've probably heard of the Met, MoMA and the Guggenheim. But there are some lesser known gems in New York City that house great exhibits which will move you.`,
		mainImageFilename: 'main.jpg',
		contentFilename: '2.md',
		linkText: 'If you are interested in traveling to New York City or you are already there, consider these popular activities:',
		links: [
			{url: 'https://selector.viator.com/tours/New-York-City/New-York-in-One-Day-Guided-Sightseeing-Tour/d687-7081NYCDAY', imgSrc: 'https://media.tacdn.com/media/attractions-splice-spp-674x446/0a/0f/74/73.jpg', title: 'New York in One Day Guided Sightseeing Tour'},
			{url: 'https://selector.viator.com/tours/New-York-City/New-York-Helicopter-Tour-Manhattan-Highlights/d687-18421P1', imgSrc: 'https://media.tacdn.com/media/attractions-splice-spp-674x446/09/93/f1/cf.jpg', title: 'New York Helicopter Tour: Manhattan Highlights'},
			{url: 'https://selector.viator.com/tours/New-York-City/Statue-of-Liberty-and-Ellis-Island-Guided-Tour/d687-5250LIBERTYELLIS', imgSrc: 'https://media.tacdn.com/media/attractions-splice-spp-674x446/07/9b/76/3f.jpg', title: 'Statue of Liberty & Ellis Island Tour: All Options'},
		],
	},
	{
		id: 3,
		date: 'November 30th, 2023',
		dateCode: 20231130,
		title: 'Best Yum Cha Places in Hong Kong',
		tagline: `If you're traveling to Hong Kong, you'll be surrounded by amazing food options. The one thing you need to try is an authentic yum cha experience.`,
		mainImageFilename: 'main.jpg',
		contentFilename: '3.md',
		linkText: 'If you want to enjoy the best food experiences in Hong Kong, check out these experiences:',
		links: [
			{url: 'https://www.viator.com/tours/Hong-Kong-SAR/Small-Group-Hong-Kong-Island-Food-Tour/d35959-9910P1', imgSrc: 'https://media.tacdn.com/media/attractions-splice-spp-674x446/07/38/40/19.jpg', title: 'Small-Group Hong Kong Island Food Tour'},
			{url: 'https://www.viator.com/tours/Hong-Kong-SAR/Small-Group-Tour-Hong-Kong-Night-Tour-of-Kowloon/d35959-9910P14', imgSrc: 'https://media.tacdn.com/media/attractions-splice-spp-674x446/09/30/b4/67.jpg', title: 'Small Group Kowloon Michelin Star Street Food and Culture Tour'},
			{url: 'https://www.viator.com/tours/Hong-Kong-SAR/3-Hour-Food-Tour-With-the-Locals-in-Tin-Hau-Hong-Kong/d35959-7812P21', imgSrc: 'https://media.tacdn.com/media/attractions-splice-spp-674x446/10/be/d7/53.jpg', title: 'Secret Food Tour With the Locals in Tin Hau Hong Kong w/ Private Tour Option'},
		],
	},
	{
		id: 4,
		date: 'December 1st, 2023',
		dateCode: 20231201,
		title: 'Our Favorite Tailors in Hong Kong',
		tagline: `A good suit is a necessity in your closet, even if you do not have to wear one everyday. And for those occasions where a suit is warranted, you'll want to make sure it fits you well. That's why a big attraction of Hong Kong is its bespoke tailors that can make you a tailored suit in 24 hours for a fraction of what you would pay for elsewhere.`,
		mainImageFilename: 'main.jpg',
		contentFilename: '4.md',
		linkText: 'If you are interested in traveling to Hong Kong or you are already there, consider these popular activities:',
		links: [
			{url: 'https://www.viator.com/tours/Hong-Kong-SAR/Hong-Kong-Walking-Tour-History-Stories-and-Snacks/d35959-73650P1', imgSrc: 'https://media.tacdn.com/media/attractions-splice-spp-674x446/10/0f/02/6f.jpg', title: 'Hong Kong Day Trip - Landmarks Visit'},
			{url: 'https://www.viator.com/tours/Hong-Kong-SAR/Kowloon-Tour-city-center-explore/d35959-73650P9', imgSrc: 'https://media.tacdn.com/media/attractions-splice-spp-674x446/10/04/17/f4.jpg', title: 'Lantau Island Day Trip - Big Buddha & Tai O'},
			{url: 'https://www.viator.com/tours/Hong-Kong-SAR/Day-Trip-to-Macau-from-Hong-Kong/d35959-7137P3', imgSrc: 'https://media.tacdn.com/media/attractions-splice-spp-674x446/11/5c/eb/07.jpg', title: 'Seat-In-Coach: One-Day Macau City Tour from Hong Kong'},
		],
	},
	{
		id: 5,
		date: 'December 3rd, 2023',
		dateCode: 20231203,
		title: 'Most Romantic Activities in Paris',
		tagline: `Paris is known as the city of love for good reason. The winding river, wide boulevards, beautiful gardens and architectural wonders set the mood perfectly for romance.`,
		mainImageFilename: 'main.jpg',
		contentFilename: '5.md',
		linkText: 'If you are looking for romantic activities in Paris, consider these popular activities:',
		links: [
			{url: 'https://www.viator.com/tours/Paris/Bateaux-Mouches-3-Hour-Seine-River-Cruise-Including-Dinner-and-Live-Music/d479-23561P3', imgSrc: 'https://media.tacdn.com/media/attractions-splice-spp-674x446/11/df/24/bd.jpg', title: 'Paris Seine River Dinner Cruise with Live Music by Bateaux Mouches'},
			{url: 'https://www.viator.com/tours/Paris/Summit-Eiffel-Tower-Priority-Access-with-Host/d479-7845P10', imgSrc: 'https://media.tacdn.com/media/attractions-splice-spp-674x446/06/74/aa/fc.jpg', title: 'Eiffel Tower Skip The Line Summit with Host'},
			{url: 'https://www.viator.com/tours/Versailles/Golden-Versailles-Palace-and-Garden-Tour/d763-67584P2', imgSrc: 'https://media.tacdn.com/media/attractions-splice-spp-674x446/11/ac/fe/a1.jpg', title: 'Versailles Palace & Garden Tour w. Skip The Line Entry from Paris'},
		],
	},
	{
		id: 6,
		date: 'December 6th, 2023',
		dateCode: 20231206,
		title: 'Where to Find the Best Pastries in Paris',
		tagline: `French pastries are the best in the world. But when you travel to Paris, you probably wonder where you should go to taste these delights. We'll show you the top five places to grab a macaron, profiterole, éclair or other pastry.`,
		mainImageFilename: 'main.jpg',
		contentFilename: '6.md',
		linkText: 'If you are looking for gastronomical delights in Paris, consider these popular activities:',
		links: [
			{url: 'https://www.viator.com/tours/Paris/Secret-Food-Tours-Paris/d479-7812P1', imgSrc: 'https://media.tacdn.com/media/attractions-splice-spp-674x446/0f/70/dc/71.jpg', title: 'Paris Walking Food Tour With Secret Food Tours'},
			{url: 'https://www.viator.com/tours/Paris/Montmartre-Food-and-Wine-Tour/d479-26729P5', imgSrc: 'https://media.tacdn.com/media/attractions-splice-spp-674x446/07/1e/ba/94.jpg', title: 'Montmartre Hill Sweet & Savory French Gourmet Food & Wine Tasting Tour'},
			{url: 'https://www.viator.com/tours/Paris/Ultimate-Paris-Food-Tour/d479-144878P1', imgSrc: 'https://media.tacdn.com/media/attractions-splice-spp-674x446/09/4b/68/91.jpg', title: 'Paris Ultimate Food Tour'},
		],
	},
	{
		id: 7,
		date: 'December 8th, 2023',
		dateCode: 20231208,
		title: 'Explore the Attractions of Shenzhen',
		tagline: `Shenzhen is known as a technology hub, but given it is the third most populous city in China and only 28 miles (45 kilometers) from Hong Kong, it is also becoming a tourist center. In this article, we'll go through the top five things to do in Shenzhen.`,
		mainImageFilename: 'main.jpg',
		contentFilename: '7.md',
		linkText: 'If you are looking to visit Shenzhen, consider these popular activities:',
		links: [
			{url: 'https://www.viator.com/tours/Shenzhen/Shenzhen-tour-guide-with-car/d27516-66137P12', imgSrc: 'https://media.tacdn.com/media/attractions-splice-spp-674x446/07/8c/fd/26.jpg', title: 'Shenzhen tour guide with car'},
			{url: 'https://www.viator.com/tours/Shenzhen/Shenzhen-Private-Flexible-Day-Tour-with-Guide-and-Driver-Service/d27516-36522P575', imgSrc: 'https://media.tacdn.com/media/attractions-splice-spp-674x446/0a/d7/82/2e.jpg', title: 'Shenzhen Private Flexible Day Tour with Guide and Driver Service'},
			{url: 'https://www.viator.com/tours/Shenzhen/Shenzhen-Private-Guided-Night-Tour-With-Civic-Center-Light-Show/d27516-87908P5', imgSrc: 'https://media.tacdn.com/media/attractions-splice-spp-674x446/07/b3/e2/05.jpg', title: 'Private Shenzhen Evening Tour With Shenzhen Top Scenic Spots'},
		],
	},
	{
		id: 8,
		date: 'December 10th, 2023',
		dateCode: 20231210,
		title: 'Wonders of the Lake Region in Italy',
		tagline: `The Lombardy region in northern Italy is also known as Italy's Lake Region for good reason. It is home not just to Milan, but also to some of the world's most stunning lakes. In this article, we'll go through our favorite lakes in Lombardy.`,
		mainImageFilename: 'main.jpg',
		contentFilename: '8.md',
		linkText: 'If you are looking to visit the Lake Region, consider these popular activities:',
		links: [
			{url: `https://www.viator.com/tours/Milan/Italy-and-Switzerland-in-One-Day-Lake-Como-and-Lugano-from-Milan/d512-3731MILANSWITZERLAND`, imgSrc: `https://media.tacdn.com/media/attractions-splice-spp-674x446/10/3e/85/0d.jpg`, title: `Italy and Switzerland Day Trip: Lake Como, Bellagio & Lugano from Milan`},
			{url: `https://www.viator.com/tours/Lake-Como/Como-Lugano-and-Bellagio-One-Day-Experience/d26113-6404P35`, imgSrc: `https://media.tacdn.com/media/attractions-splice-spp-674x446/11/8f/70/73.jpg`, title: `Como, Lugano and Bellagio Experience with Exclusive Boat Cruise`},
			{url: `https://www.viator.com/tours/Lake-Maggiore/Lake-Maggiore-Day-Trip-from-Milan/d26112-2872LAKEMAGGIORE`, imgSrc: `https://media.tacdn.com/media/attractions-splice-spp-674x446/0f/9a/ca/ab.jpg`, title: `Unique Lake Maggiore Day Trip from Milan`},
		],
	},
	{
		id: 9,
		date: 'December 13th, 2023',
		dateCode: 20231213,
		title: 'Picturesque Towns in Provence',
		tagline: `The French region of Provence has one of the most beautiful countrysides in the world. It is also full of the prettiest towns you will ever see. In this article, we'll go through our top three towns in Provence that you should visit.`,
		mainImageFilename: 'main.jpg',
		contentFilename: '9.md',
		linkText: 'If you are looking to visit Provence, consider these popular activities:',
		links: [
			{url: `https://www.viator.com/tours/Avignon/Small-Group-Full-Day-Highlights-of-Provence-Tour-from-Avignon/d483-31254P1`, imgSrc: `https://media.tacdn.com/media/attractions-splice-spp-674x446/06/8e/12/15.jpg`, title: `Provence Highlights Full-Day Tour from Avignon`},
			{url: `https://www.viator.com/tours/Avignon/Luberon-and-its-Colors-Tour-from-Avignon/d483-31254P8`, imgSrc: `https://media.tacdn.com/media/attractions-splice-spp-674x446/06/e7/12/2e.jpg`, title: `Luberon, Roussillon & Gordes Half-Day Tour from Avignon`},
			{url: `https://www.viator.com/tours/Aix-en-Provence/Luberon-Villages-Half-Day-Tour-from-Aix-en-Provence/d5228-5849LUBERONVILLAGES`, imgSrc: `https://media.tacdn.com/media/attractions-splice-spp-674x446/06/71/af/ca.jpg`, title: `Luberon Villages Half-Day Tour from Aix-en-Provence`},
		],
	},
	{
		id: 10,
		date: 'December 15th, 2023',
		dateCode: 20231215,
		title: 'Best Desert Resorts in the United Arab Emirates',
		tagline: `The UAE (United Arab Emirates) is known for the glitz and glam of its skyscraper filled cities like Dubai, but its true natural beauty lies in the sand dunes and shifting sands of its vast desert. In this article, we'll go through the best desert resorts where you can get the best desert experience.`,
		mainImageFilename: 'main.jpg',
		contentFilename: '10.md',
		linkText: 'If you are looking to experience the UAE desert, consider these popular activities:',
		links: [
			{url: `https://www.viator.com/tours/Dubai/Red-Dunes-By-Quad-Bike-Sandboarding-Camel-Ride-and-BB-Dinner/d828-91421P4`, imgSrc: `https://media.tacdn.com/media/attractions-splice-spp-674x446/0b/24/ea/75.jpg`, title: `Dubai: Red Dunes ATV, Sandsurf, Camels, Stargazing & 5* BBQ at Al Khayma Camp`},
			{url: `https://www.viator.com/tours/Dubai/Dubai-Desert-4x4-dune-bashing-30-min-ATV-Quad-ride-camel-ride-live-shows-dinner/d828-34459P40`, imgSrc: `https://media.tacdn.com/media/attractions-splice-spp-674x446/09/27/d7/05.jpg`, title: `Dubai Desert 4x4 Dune Bashing, Self-Ride 60min ATV Quad, Camel Ride,Shows,Dinner`},
			{url: `https://www.viator.com/tours/Dubai/Premium-Red-Dunes-and-Camel-Safari-with-BBQ-at-Al-Khayma-Camp/d828-91421P12`, imgSrc: `https://media.tacdn.com/media/attractions-splice-spp-674x446/0b/24/c8/54.jpg`, title: `Dubai: Premium Red Dunes, Camels, Stargazing & 5* BBQ at Al Khayma Camp`},
		],
	},
	{
		id: 11,
		date: 'December 18th, 2023',
		dateCode: 20231218,
		title: 'Tranquil Countryside Destinations in the United Kingdom',
		tagline: `The English countryside is known for its beauty, charm and peacefulness - a haven from the hustle and bustle of the big cities. In this article, we'll go through our top five English countryside destinations to visit.`,
		mainImageFilename: 'main.jpg',
		contentFilename: '11.md',
		linkText: 'If you are looking to enjoy the English countryside, consider these popular activities:',
		links: [
			{url: `https://www.viator.com/tours/London/Bath-Stonehenge-and-The-English-Countryside-Day-Tour-from-London/d737-21204P1`, imgSrc: `https://media.tacdn.com/media/attractions-splice-spp-674x446/06/fb/ee/dc.jpg`, title: `Bath, Stonehenge and The English Countryside Day Tour from London`},
			{url: `https://www.viator.com/tours/Edinburgh/Loch-Ness-Highlands-and-Whisky-Full-Day-Tour-from-Edinburgh/d739-30740P1`, imgSrc: `https://media.tacdn.com/media/attractions-splice-spp-674x446/09/b4/78/8f.jpg`, title: `Loch Ness, Scottish Highlands, Glencoe & Pitlochry Tour from Edinburgh`},
			{url: `https://www.viator.com/tours/Cotswolds/Tour-of-the-Cotswolds-from-Moreton-in-Marsh/d28629-17949P8`, imgSrc: `https://media.tacdn.com/media/attractions-splice-spp-674x446/0f/d8/b4/e9.jpg`, title: `Cotswolds in a Day Tour from Moreton-in-Marsh / Stratford-on-Avon`},
		],
	},
	{
		id: 12,
		date: 'December 20th, 2023',
		dateCode: 20231220,
		title: 'Highlights of the Julian Alps in Slovenia',
		tagline: `Slovenia's Julian Alps are one of the most underrated mountain regions in Europe. As celebrated as the Swiss Alps, French Alps or even Italian Dolomites are, the Julian Alps can definitely hold its own. In this article we'll go through our top highlights for you to consider when planning a trip to Slovenia's Julian Alps.`,
		mainImageFilename: 'main.jpg',
		contentFilename: '12.md',
		linkText: 'If you are looking to visit Slovenia, consider these popular activities:',
		links: [
			{url: `https://www.viator.com/tours/Ljubljana/Postojna-Caves-and-Predjama-Castle-Tour-from-Ljubljana/d5257-3312LJ_POS`, imgSrc: `https://media.tacdn.com/media/attractions-splice-spp-674x446/0f/fa/e6/51.jpg`, title: `Postojna Cave and Predjama Castle - Entrance Tickets Included`},
			{url: `https://www.viator.com/tours/Ljubljana/Slovenia-in-One-Day-Small-Group-Day-Trip-to-Lake-Bled-Postojna-Cave-and-Predjama-Castle-from-Ljubljana/d5257-6993SVN1DAY`, imgSrc: `https://media.tacdn.com/media/attractions-splice-spp-674x446/06/e0/6d/12.jpg`, title: `Slovenia in One Day: Small-Group Day Trip to Lake Bled, Postojna Cave and Predjama Castle from Ljubljana`},
			{url: `https://www.viator.com/tours/Ljubljana/Lake-Bled-and-Bled-Castle-Tour-from-Ljubljana/d5257-6993LAKEBLED`, imgSrc: `https://media.tacdn.com/media/attractions-splice-spp-674x446/06/73/fa/94.jpg`, title: `Lake Bled and Bled Castle Half-day Small Group Tour from Ljubljana`},
		],
	},
	{
		id: 13,
		date: 'December 22nd, 2023',
		dateCode: 20231222,
		title: 'Automobile Museums and Factory Tours in Germany',
		tagline: `Germany is known for its precision engineering, especially in the field of automobiles. For the motor enthusiasts (e.g., gearheads, petrolheads and revheads) out there, Germany is the perfect place to appreciate and pay homage to the birthplace of the modern-day motor car. In this article, we'll go through five auto musuems and factory tours you can visit in Germany.`,
		mainImageFilename: 'main.jpg',
		contentFilename: '13.md',
		linkText: 'If you are looking to visit Germany, consider these popular activities:',
		links: [
			{url: `https://www.viator.com/tours/Munich/Wright-Europe-Escorted-Auto-Tours-Trips-And-Vacations/d487-179466P1`, imgSrc: `https://media.tacdn.com/media/attractions-splice-spp-674x446/0b/eb/cf/d6.jpg`, title: `Wright Europe Escorted Auto Tours, Trips And Vacations`},
			{url: `https://www.viator.com/tours/Frankfurt/from-Frankfurt-historic-Heidelberg-Mercedes-Benz-museum-and-Schwetzingen-palace/d489-138200P9`, imgSrc: `https://media.tacdn.com/media/attractions-splice-spp-674x446/0b/16/92/79.jpg`, title: `from Frankfurt - Heidelberg, Mercedes Museum & Christmas market`},
			{url: `https://www.viator.com/tours/Berlin/Discover-Berlin-Half-Day-Walking-Tour/d488-3817DISCOVER`, imgSrc: `https://media.tacdn.com/media/attractions-splice-spp-674x446/07/b0/78/0a.jpg`, title: `Discover Berlin Half-Day Walking Tour`},
		],
	},
	{
		id: 14,
		date: 'December 29th, 2023',
		dateCode: 20231229,
		title: 'Top Stops Along the Overseas Highway in the Florida Keys',
		tagline: `The Florida Keys is the southernmost part of the United States and one of the most beautiful places in the world. The Overseas Highway is 113 miles long and connects the Florida Keys to the mainland. In this article, we'll go through our favorite stops along the Overseas Highway.`,
		mainImageFilename: 'main.jpg',
		contentFilename: '14.md',
		linkText: 'If you are looking to visit the Florida Keys, consider these popular activities:',
		links: [
			{url: `https://www.viator.com/tours/Key-West/Key-West-Reef-Snorkeling-Cruise/d661-3800REEFSAIL`, imgSrc: `https://media.tacdn.com/media/attractions-splice-spp-674x446/0f/dd/d7/04.jpg`, title: `Key West Florida Reef Half-Day Snorkeling Excursion`},
			{url: `https://www.viator.com/tours/Key-Largo/Half-Day-Snorkel-Trip/d23475-204315P2`, imgSrc: `https://media.tacdn.com/media/attractions-splice-spp-674x446/0b/1a/02/dd.jpg`, title: `Half Day Snorkel Trip on Reefs in the Florida Keys`},
			{url: `https://www.viator.com/tours/Key-Largo/Sunset-Cruise-on-the-Florida-Bay/d23475-204315P4`, imgSrc: `https://media.tacdn.com/media/attractions-splice-spp-674x446/0b/95/99/f1.jpg`, title: `Sunset Cruise on the Florida Bay`},
		],
	},
	{
		id: 15,
		date: 'January 2nd, 2024 (updated January 16th, 2024)',
		dateCode: 20240116,
		title: 'World Class Restaurants at the CIA in the Hudson Valley',
		tagline: `The Culinary Institute of America is the premier culinary college in the world. Its main campus is located in Hyde Park in the Hudson Valley of New York State. For food enthusiasts, you can enjoy a meal at one of its restaurants. In this article, we'll go through each of the CIA's restaurants on campus.`,
		mainImageFilename: 'main.jpg',
		contentFilename: '15.md',
		linkText: 'If you are looking to visit the Hudson Valley in New York State, consider these popular activities:',
		links: [
			{url: `https://www.viator.com/tours/The-Catskills/Hudson-River-Sightseeing-Cruise/d50779-38166P1`, imgSrc: `https://media.tacdn.com/media/attractions-splice-spp-674x446/0b/35/9f/c1.jpg`, title: `Hudson River Sightseeing Cruise from Kingston`},
			{url: `https://www.viator.com/tours/The-Catskills/Farm-Experience-and-Llama-Alpaca-Hike/d50779-174824P1`, imgSrc: `https://media.tacdn.com/media/attractions-splice-spp-674x446/07/b8/ee/c7.jpg`, title: `Llama/Alpaca Hike and Farm Tour`},
			{url: `https://www.viator.com/tours/New-York-City/Woodbury-Common-Premium-Outlet-Shopping-Private-Day-Trip-by-SUV-from-NYC/d687-30688P5`, imgSrc: `https://media.tacdn.com/media/attractions-splice-spp-674x446/0a/64/17/eb.jpg`, title: `Woodbury Common Premium Outlet Shopping Private Day Trip by SUV from NYC`},
		],
	},
	{
		id: 16,
		date: 'January 4th, 2024',
		dateCode: 20240104,
		title: 'Best Bakeries in New York City',
		tagline: `New York City is a melting pot of different cultures and this is evident in the variety of its top notch bakeries. In this article, we'll go through our favorite bakeries in the city and why you should give them a try.`,
		mainImageFilename: 'main.jpg',
		contentFilename: '16.md',
		linkText: 'If you are interested in traveling to New York City or you are already there, consider these popular activities:',
		links: [
			{url: 'https://selector.viator.com/tours/New-York-City/New-York-in-One-Day-Guided-Sightseeing-Tour/d687-7081NYCDAY', imgSrc: 'https://media.tacdn.com/media/attractions-splice-spp-674x446/0a/0f/74/73.jpg', title: 'New York in One Day Guided Sightseeing Tour'},
			{url: 'https://selector.viator.com/tours/New-York-City/New-York-Helicopter-Tour-Manhattan-Highlights/d687-18421P1', imgSrc: 'https://media.tacdn.com/media/attractions-splice-spp-674x446/09/93/f1/cf.jpg', title: 'New York Helicopter Tour: Manhattan Highlights'},
			{url: 'https://selector.viator.com/tours/New-York-City/Statue-of-Liberty-and-Ellis-Island-Guided-Tour/d687-5250LIBERTYELLIS', imgSrc: 'https://media.tacdn.com/media/attractions-splice-spp-674x446/07/9b/76/3f.jpg', title: 'Statue of Liberty & Ellis Island Tour: All Options'},
		],
	},
	{
		id: 17,
		date: 'January 8th, 2024',
		dateCode: 20240108,
		title: 'Stunning National Parks in Japan',
		tagline: `In addition to its incredible food, unique culture and advanced technology, Japan is home to some of the most beautiful national parks in the world. In this article, we will take you through our top five Japanese national parks.`,
		mainImageFilename: 'main.jpg',
		contentFilename: '17.md',
		linkText: 'If you are interested in traveling to Japan or you are already there, consider these popular activities:',
		links: [
			{url: `https://www.viator.com/tours/Naha/From-Naha-Kerama-Islands-snorkeling-tour-1-day-enjoyment-plan/d4666-210836P4`, imgSrc: `https://media.tacdn.com/media/attractions-splice-spp-674x446/0b/e0/38/3c.jpg`, title: `Naha: Full-Day Snorkeling Experience in the Kerama Islands, Okinawa`},
			{url: `https://www.viator.com/tours/Nikko/Nikko-Full-day-Private-Custom-Tour-with-National-Licensed-Guide/d50514-92136P69`, imgSrc: `https://media.tacdn.com/media/attractions-splice-spp-674x446/09/af/f4/f3.jpg`, title: `Nikko Full-Day Private Tour with Government-Licensed Guide`},
			{url: `https://www.viator.com/tours/Tokyo/Mt-Fuji-Day-Trip-including-Lake-Ashi-Sightseeing-Cruise-from-Tokyo/d334-2142TYO_F880_F830`, imgSrc: `https://media.tacdn.com/media/attractions-splice-spp-674x446/07/aa/09/aa.jpg`, title: `Mt Fuji, Hakone, Lake Ashi Cruise 1 Day Bus Trip from Tokyo`},
		],
	},
	{
		id: 18,
		date: 'January 16th, 2024',
		dateCode: 20240116,
		title: 'Highlights In and Around Shenandoah National Park',
		tagline: `Shenandoah National Park is only the 21st most visited national park in the United States. But its accessibility, ample hiking trails and recreation friendly attributes make it one of the most underrated national parks. In this article, we will go through the top highlights of a trip to Shenandoah National Park.`,
		mainImageFilename: 'main.jpg',
		contentFilename: '18.md',
		linkText: 'If you are interested in traveling to Shenandoah National Park or you are already there, consider these popular activities:',
		links: [
			{url: `https://www.viator.com/tours/Washington-DC/2-Day-Hershey-Adventure-Aquarium-Cherry-Blossom-New-York-Departur-CB2B/d657-132218P156`, imgSrc: `https://media.tacdn.com/media/attractions-splice-spp-674x446/0e/8a/3d/03.jpg`, title: `BEST Luray Caverns and Shenandoah Skyline Drive All-Day Tour`},
			{url: `https://www.viator.com/tours/Virginia/Self-Guided-Audio-Driving-Tour-in-Shenandoah-National-Park/d22229-309754P28`, imgSrc: `https://media.tacdn.com/media/attractions-splice-spp-674x446/11/30/e0/7f.jpg`, title: `Self-Guided Audio Driving Tour in Shenandoah National Park`},
			{url: `https://www.viator.com/tours/Moab/National-Parks-Self-Guided-Driving-Tours-Bundle/d5600-259428P10`, imgSrc: `https://media.tacdn.com/media/attractions-splice-spp-674x446/0f/9c/5a/a1.jpg`, title: `25+ National Parks Self-Guided Driving Tours Bundle`},
		],
	},
	{
		id: 19,
		date: 'January 18th, 2024',
		dateCode: 20240118,
		title: 'Cooking Classes to Take Across Europe',
		tagline: `Food enthusiasts know that Europe is a wonderful place to visit for culinary experiences. Each country has a very unique cuisine developed over a long, rich history. In this article, we will go through the best types of cooking classes to take when visiting Barcelona in Spain, Paris in France, Rome in Italy and Athens in Greece.`,
		mainImageFilename: 'main.jpg',
		contentFilename: '19.md',
		linkText: 'If you are interested in taking cooking classes in Europe, consider these popular activities:',
		links: [
			{url: `https://www.viator.com/tours/Barcelona/Interactive-Spanish-Cooking-Experience-in-Barcelona/d562-16637P1`, imgSrc: `https://media.tacdn.com/media/attractions-splice-spp-674x446/07/b3/fb/c6.jpg`, title: `Interactive Spanish Cooking Experience in Barcelona`},
			{url: `https://www.viator.com/tours/Rome/3-in-1-Cooking-Class-in-Piazza-Navona-Fettuccine-Ravioli-and-Tiramisu/d511-21369P20`, imgSrc: `https://media.tacdn.com/media/attractions-splice-spp-674x446/09/db/39/b2.jpg`, title: `3 in 1 Cooking Class Piazza Navona: Fettuccine Ravioli and Tiramisu`},
			{url: `https://www.viator.com/tours/Athens/Traditional-Greek-Cooking-Class/d496-49232P1`, imgSrc: `https://media.tacdn.com/media/attractions-splice-spp-674x446/0a/33/e3/05.jpg`, title: `Athens 4-Hour Cooking Class with Central Market Visit`},
		],
	},
	{
		id: 20,
		date: 'January 22nd, 2024',
		dateCode: 20240122,
		title: 'Chocolate Factory Tours in Switzerland',
		tagline: `A visit to Switzerland is incomplete without experiencing the delicious creations of its master chocolatiers. For chocolate lovers, this will mean a visit to one of Switzerland's several chocolate factories. In this article, we will take you through the top three chocolate factories in Switzerland to visit.`,
		mainImageFilename: 'main.jpg',
		contentFilename: '20.md',
		linkText: 'If you are interested in visiting Switzerland, consider these popular activities:',
		links: [
			{url: `https://www.viator.com/tours/Geneva/Chocolate-Flavours-Tours/d578-58394P1`, imgSrc: `https://media.tacdn.com/media/attractions-splice-spp-674x446/0b/22/19/44.jpg`, title: `Geneva 3-hour Chocolate Tasting Tour and Old Town Visit`},
			{url: `https://www.viator.com/tours/Zurich/Zurich-Tour-Including-Lake-Cruise-and-Lindt-Chocolate-Factory-Outlet/d577-3885CHOC`, imgSrc: `https://media.tacdn.com/media/attractions-splice-spp-674x446/0c/0e/58/3b.jpg`, title: `Zurich Sightseeing With Lake Cruise and Lindt Home of Chocolate`},
			{url: `https://www.viator.com/tours/Geneva/Day-Trip-to-Gruyeres-Medieval-Village-and-Chocolate-Factory/d578-2510KTG352`, imgSrc: `https://media.tacdn.com/media/attractions-splice-spp-674x446/06/88/71/39.jpg`, title: `Gruyères Trip With Cheese and Chocolate Factory Visit from Geneva`},
		],
	},
	{
		id: 21,
		date: 'January 24th, 2024',
		dateCode: 20240124,
		title: 'Pasta Pilgramage to Academia Barilla in Parma',
		tagline: `Barilla is the world's largest producer of pasta. If you've ever shopped for pasta, you've probably seen their products on most shelves. The Academia Barilla was founded in 2004 by Barilla as an institution to celebrate Italian culinary culture. In this article, we will go through how you can enjoy a visit to the Academia Barilla in Parma, Italy.`,
		mainImageFilename: 'main.jpg',
		contentFilename: '21.md',
		linkText: 'If you are interested in visiting Parma, consider these popular activities:',
		links: [
			{url: `https://www.viator.com/tours/Parma/Parmigiano-cheese-and-Parma-ham-Tour/d27234-208820P1`, imgSrc: `https://media.tacdn.com/media/attractions-splice-spp-674x446/12/49/59/9e.jpg`, title: `Tour Parmigiano Reggiano dairy and Parma ham`},
			{url: `https://www.viator.com/tours/Parma/Parma-Walking-Food-Tour/d27234-237138P1`, imgSrc: `https://media.tacdn.com/media/attractions-splice-spp-674x446/0b/47/c5/36.jpg`, title: `Private Half Day Parma Food Walking Tour`},
			{url: `https://www.viator.com/tours/Parma/Parma-Food-Valley-Gourmet-Tour-Easy/d27234-6739P3`, imgSrc: `https://media.tacdn.com/media/attractions-splice-spp-674x446/06/73/42/2d.jpg`, title: `Parma Food Valley Gourmet Private Tour Easy`},
		],
	},
	{
		id: 22,
		date: 'January 29th, 2024',
		dateCode: 20240129,
		title: 'Fashion Brands Attractions in Italy',
		tagline: `Italy is the home of global luxury fashion brands known by all fashion lovers. Apart from shopping at their flagship stores, some brands offer museums and exhibits to celebrate their creations and artistic inspirations. In this article, we will go through the top attractions offered by luxury fashion brands in Italy.`,
		mainImageFilename: 'main.jpg',
		contentFilename: '22.md',
		linkText: 'If you are interested in exploring fashion in Italy, consider these popular activities:',
		links: [
			{url: `https://www.viator.com/tours/Milan/Private-Tour-of-Milan-Fashion-District/d512-24380P266`, imgSrc: `https://media.tacdn.com/media/attractions-splice-spp-674x446/06/70/1f/85.jpg`, title: `Treasures of Milan: Fashion and Design Private Tour`},
			{url: `https://www.viator.com/tours/Milan/Milan-Fashion-Experience-Tour/d512-41878P1`, imgSrc: `https://media.tacdn.com/media/attractions-splice-spp-674x446/12/48/7f/ee.jpg`, title: `Milan Fashion Tour`},
			{url: `https://www.viator.com/tours/Rome/Fendi-Experience-the-pure-essence-of-Roman-high-fashion/d511-205229P3`, imgSrc: `https://media.tacdn.com/media/attractions-splice-spp-674x446/09/c6/86/22.jpg`, title: `Fendi Experience: the pure essence of Roman high fashion`},
		],
	},
	{
		id: 23,
		date: 'February 1st, 2024',
		dateCode: 20240201,
		title: 'Popular Events for Dog Lovers in New York',
		tagline: `Dogs are loving, loyal pets which is why so many people are dog lovers. Around 40% of households in the United States own a dog and many more wish they did. In New York, there are a number of events for dog lovers to celebrate and learn more about these wonderful pets. In this article, we will take you through some of the most popular of these events in New York.`,
		mainImageFilename: 'main.jpg',
		contentFilename: '23.md',
		linkText: 'If you are interested in visiting New York or are already there, consider these popular activities:',
		links: [
			{url: `https://www.viator.com/tours/New-York-City/New-York-in-One-Day-Guided-Sightseeing-Tour/d687-7081NYCDAY`, imgSrc: `https://media.tacdn.com/media/attractions-splice-spp-674x446/07/25/13/74.jpg`, title: `New York in One Day Guided Sightseeing Tour`},
			{url: `https://www.viator.com/tours/New-York-City/Statue-of-Liberty-and-Ellis-Island-Guided-Tour/d687-5250LIBERTYELLIS`, imgSrc: `https://media.tacdn.com/media/attractions-splice-spp-674x446/07/72/13/b5.jpg`, title: `Statue of Liberty & Ellis Island Tour: All Options`},
			{url: `https://www.viator.com/tours/Niagara-Falls/Niagara-Falls-in-One-Day-Deluxe-Sightseeing-Tour-of-American-and-Canadian-Sides/d23183-2972COMBO`, imgSrc: `https://media.tacdn.com/media/attractions-splice-spp-674x446/08/bd/11/c8.jpg`, title: `Niagara Falls in 1 Day: Tour of American and Canadian Sides`},
		],
	},
	{
		id: 24,
		date: 'February 5th, 2024',
		dateCode: 20240205,
		title: 'Top Sports Car Museums in Italy',
		tagline: `For auto enthusiasts, the home of sports cars is undoubtedly Italy. The country is home to names such as Lamborghini, Ferrari, Maserati and Alfa Romeo. In this article, we will go through the top automobile and sports car museums in Italy.`,
		mainImageFilename: 'main.jpg',
		contentFilename: '24.md',
		linkText: 'If you are interested in visiting Italy or are already there, consider these popular activities:',
		links: [
			{url: `https://www.viator.com/tours/Bologna/Ferrari-Lamborghini-Pagani-Tour/d791-60375P10`, imgSrc: `https://media.tacdn.com/media/attractions-splice-spp-674x446/07/1b/68/2c.jpg`, title: `Ferrari Lamborghini Pagani Factories and Museums - Tour from Bologna`},
			{url: `https://www.viator.com/tours/Bologna/Summer-Autumn-2020-Cars-and-Food-Lambo-Ferrari-and-Maserati-Parmesan-and-BalsamicVinegar/d791-55504P13`, imgSrc: `https://media.tacdn.com/media/attractions-splice-spp-674x446/0b/17/17/27.jpg`, title: `Cars&Food: Lambo, Ferrari, Parmesan, Balsamic Vinegar, lunch, private transport`},
			{url: `https://www.viator.com/tours/Bologna/Alfa-Romeo-Museum-Monza-Autodrome-Tour/d791-60375P4`, imgSrc: `https://media.tacdn.com/media/attractions-splice-spp-674x446/0d/f8/3d/cb.jpg`, title: `Ferrari Lamborghini Maserati Factories and Museums - Tour from Bologna`},
		],
	},
];

export default Articles;