import { useState } from "react";
import { Link, useNavigate } from 'react-router-dom';

import { styled, alpha } from '@mui/material/styles';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Button from '@mui/material/Button';
import Logo from '../resources/logo.png';
import InputBase from '@mui/material/InputBase';
import SearchIcon from '@mui/icons-material/Search';
import MenuIcon from '@mui/icons-material/Menu';
import InputAdornment from '@mui/material/InputAdornment';
import IconButton from '@mui/material/IconButton';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';

const Search = styled('div')(({ theme }) => ({
    position: 'relative',
    borderRadius: theme.shape.borderRadius,
    backgroundColor: alpha(theme.palette.common.white, 0.60),
    '&:hover': {
        backgroundColor: alpha(theme.palette.common.white, 0.90),
    },
    marginLeft: 0,
    width: '100%',
    [theme.breakpoints.up('sm')]: {
        marginLeft: theme.spacing(1),
        width: 'auto',
    },
}));

const StyledInputBase = styled(InputBase)(({ theme }) => ({
    color: theme.palette.primary.main,
    '& .MuiInputBase-input': {
        padding: theme.spacing(1, 1, 1, 1),
        transition: theme.transitions.create('width'),
        width: '100%',
        [theme.breakpoints.up('sm')]: {
            width: '24ch',
            '&:focus': {
                width: '28ch',
                flex: 1,
            },
        },
    },
}));

const Header = (props) => {
    const navigate = useNavigate();
    const [anchorEl, setAnchorEl] = useState(null);

    const handleSearch = (event) => {
        event.preventDefault();
        props.query && navigate(`/search-results/${props.query}`);
    };

    return (
        <AppBar id="Header" position="static" style={{ background: 'transparent', boxShadow: 'none'}}>
            <Toolbar id="headerToolbar">
                <div id="navButtons">
                    <div className="webOnly">
                        <Link to="/" style={{ textDecoration: 'none' }}><Button variant="text" sx={{fontWeight: 'bold'}}><img src={Logo} alt="Logo of a hot air balloon" height="24"/>&nbsp;Wanderful Dreams</Button></Link>
                        <Link to="/about" style={{ textDecoration: 'none' }}><Button variant="text">About</Button></Link>
                        <Link to="/quizzes" style={{ textDecoration: 'none' }}><Button variant="text">Quizzes</Button></Link>
                        <Link to="/articles" style={{ textDecoration: 'none' }}><Button variant="text">Articles</Button></Link>
                    </div>
                    <div className="mobileOnly">
                        <IconButton edge="start" color="inherit" aria-label="menu" onClick={e => setAnchorEl(e.currentTarget)}>
                            <MenuIcon/>
                        </IconButton>
                        <Menu className="mobileOnly" anchorEl={anchorEl} keepMounted open={Boolean(anchorEl)} onClose={() => setAnchorEl(null)}>
                            <MenuItem onClick={() => setAnchorEl(null)}><Link to="/about" style={{ textDecoration: 'none', color: '#20458C' }}>About</Link></MenuItem>
                            <MenuItem onClick={() => setAnchorEl(null)}><Link to="/quizzes" style={{ textDecoration: 'none', color: '#20458C' }}>Quizzes</Link></MenuItem>
                            <MenuItem onClick={() => setAnchorEl(null)}><Link to="/articles" style={{ textDecoration: 'none', color: '#20458C' }}>Articles</Link></MenuItem>
                        </Menu>
                        <Link to="/"><Button variant="text" sx={{fontWeight: 'bold'}}><img src={Logo} alt="Logo of a hot air balloon" height="24"/></Button></Link>
                    </div>
                </div>
                <form onSubmit={handleSearch}>
                    <Search className="webOnly">
                        <StyledInputBase
                            placeholder="Search quizzes and articles…"
                            inputProps={{ 'aria-label': 'search' }}
                            value={props.query}
                            onChange={(event) => {
                                props.setQuery(event.target.value);
                            }}
                            endAdornment={
                                <InputAdornment position="end">
                                    <IconButton aria-label="search" color='primary' type="submit">
                                        <SearchIcon />
                                    </IconButton>
                                </InputAdornment>
                            }
                        />
                    </Search>
                    <Search className="mobileOnly">
                        <StyledInputBase
                            placeholder="Search…"
                            inputProps={{ 'aria-label': 'search' }}
                            value={props.query}
                            onChange={(event) => {
                                props.setQuery(event.target.value);
                            }}
                            endAdornment={
                                <InputAdornment position="end">
                                    <IconButton aria-label="search" color='primary' type="submit">
                                        <SearchIcon />
                                    </IconButton>
                                </InputAdornment>
                            }
                        />
                    </Search>
                </form>
            </Toolbar>
        </AppBar>
    );
};

export default Header;