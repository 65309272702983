import { Link } from 'react-router-dom';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import { CardActionArea } from '@mui/material';

const QuizTile = (props) => {
    return (
        <Card id="QuizTile">
            <Link to={`/quizzes/${props.quiz.title.toString().replace(/\s+/g, '-').toLowerCase()}`} style={{ textDecoration: 'none' }}>
                <CardActionArea>
                    <CardMedia
                        component="img"
                        height="140"
                        image={require(`../resources/quiz_images/${props.quiz.id}/${props.quiz.mainImageFilename}`)}
                        alt="placeholder image"
                    />
                    <CardContent>
                        <h4>{props.quiz.title}</h4>
                        <h5>{props.quiz.subtitle}</h5>
                    </CardContent>
                </CardActionArea>
            </Link>
        </Card>
    );
};

export default QuizTile;