import { Link } from 'react-router-dom';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import { CardActionArea } from '@mui/material';

const LinkTile = (props) => {
    return (
        <Card id="LinkTile">
            <Link to={`${props.link.url}?pid=P00146553&mcid=42383&medium=link&campaign=${props.campaign}`} target="_blank" style={{ textDecoration: 'none' }} rel="nofollow">
                <CardActionArea>
                    <CardMedia
                        component="img"
                        height="140"
                        src={props.link.imgSrc}
                        alt="image"
                    />
                    <CardContent>
                        <h4>{props.link.title}</h4>
                    </CardContent>
                </CardActionArea>
            </Link>
        </Card>
    );
};

export default LinkTile;