import { useEffect, useState } from "react";
import Paper from '@mui/material/Paper';
import Markdown from 'react-markdown';
import privacyMarkdown from '../markdown/Privacy.md';

const PrivacyPage = (props) => {
	useEffect(() => {
		props.setTitle('Privacy Policy');
		props.setMetaDescription(`This page outlines the privacy policy for Wanderful Dreams`);
	});	

	const [content, setContent] = useState('');

	useEffect(() => {
		fetch(privacyMarkdown)
			.then((res) => res.text())
			.then((text) => setContent(text));
	}, []);

    return (
        <Paper id="PrivacyPage">
        	<h1>Privacy policy</h1>
    		<div id="textSection">
    			<Markdown>{content}</Markdown>
    		</div>
        </Paper>
    );
};

export default PrivacyPage;