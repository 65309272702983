import { useState, useEffect } from "react";
import { Link, useParams } from 'react-router-dom';
import Paper from '@mui/material/Paper';

import QuizQuestion from '../components/QuizQuestion.js';
import QuizAnswer from '../components/QuizAnswer.js';
import LinkTile from '../components/LinkTile.js';

const QuizPage = (props) => {
    const [responses, setResponses] = useState({});
    const [quizContent, setQuizContent] = useState({});
    let { quizTitle } = useParams();
    const quiz = props.quizzes.find(quiz => quiz.title.toString().replace(/\s+/g, '-').toLowerCase() === quizTitle);

    useEffect(() => {
        const importQuizContent = async () => {
            if(!quiz) return {};
            const module = await import(`../quizzes/${quiz.contentFilename}`);
            const content = module.default;
            return content;
        };
        importQuizContent().then(content => setQuizContent(content));
    });

    useEffect(() => {
        quiz && props.setTitle(quiz.title);
        quiz && props.setMetaDescription(`Take a quiz and test your knowledge on: ${quiz.title} - ${quiz.subtitle}`);
    });

    if(quiz === undefined) return window.location.href = '/no-match';
    
    const quizStructuredData = {
        '@context': 'https://schema.org',
        '@type': 'Quiz',
        name: quiz.title,
        about: {
            '@type': 'Thing',
            name: quiz.title,
        },
        hasPart: quizContent.questions && quizContent.questions.map(question => ({
            '@type': 'Question',
            eduQuestionType: 'Multiple choice',
            learningResourceType: 'Practice problem',
            image: require(`../resources/quiz_images/${quiz.id}/${question.questionImageFilename}`),
            text: question.questionText,
            acceptedAnswer: {
                '@type': 'Answer',
                text: question.answer.option,
            },
            suggestedAnswer: question.responseOptions.filter(option => option !== question.answer.option).map(option => ({
                '@type': 'Answer',
                text: option,
            })),
        })),
    };

    let score = quizContent.questions && quizContent.questions.reduce((accumulator, question) => accumulator + (responses[question.number]===question.answer.option ? 1 : 0), 0);    

    return (
        <div id="QuizPage">
            <script type="application/ld+json" dangerouslySetInnerHTML={{__html: JSON.stringify(quizStructuredData)}}></script>
            <h1>{`Quiz: ${quiz.title} - ${quiz.subtitle}`}</h1>
            <hr/>

	        {quizContent.questions && quizContent.questions.map(question => {
                return !responses[question.number] ?
                    <QuizQuestion question={question} questionsTotal={quizContent.questions && quizContent.questions.length} quizId={quiz.id} key={question.number} responses={responses} setResponses={setResponses}/>
                    :
                    <QuizAnswer question={question} questionsTotal={quizContent.questions && quizContent.questions.length} quizId={quiz.id} key={question.number} correct={responses[question.number]===question.answer.option}/>
            })}

            {Object.keys(responses).length === (quizContent.questions && quizContent.questions.length) &&
                <Paper id="QuizResultsSection">
                    <h1>{`Your score is ${score} out of ${quizContent.questions && quizContent.questions.length}`}</h1>
                    <p>Check out our other <Link to="/quizzes">quizzes</Link> and <Link to="/articles">articles</Link>! Discover exciting destinations and experiences!</p>
                    <p>{quizContent.resultsLinks && quizContent.resultsLinks.text}</p>
                    <div id="linksSection">
                        {quizContent.resultsLinks && quizContent.resultsLinks.links.map(link=>(
                            <LinkTile link={link} key={link.url} campaign={`quiz${props.quizId}`}/>
                        ))}
                    </div>
                </Paper>
            }
        </div>
    );
};

export default QuizPage;