import { useEffect, useState } from "react";
import Paper from '@mui/material/Paper';
import Markdown from 'react-markdown';
import termsOfUseMarkdown from '../markdown/TermsOfUse.md';

const TermsOfUsePage = (props) => {
	useEffect(() => {
		props.setTitle('Terms of Use');
		props.setMetaDescription(`This page outlines the terms of use for Wanderful Dreams`);
	});

	const [content, setContent] = useState('');

	useEffect(() => {
		fetch(termsOfUseMarkdown)
			.then((res) => res.text())
			.then((text) => setContent(text));
	});

    return (
        <Paper id="TermsOfUsePage">
        	<h1>Terms of Use</h1>
    		<div id="textSection">
    			<Markdown>{content}</Markdown>
    		</div>
        </Paper>
    );
};

export default TermsOfUsePage;