import logo from '../resources/jumboPic1.png';

const Banner = (props) => {
    return (
        <div id="Banner">
        	<img src={logo} alt="logo of a hot air balloon floating through a dreamy landscape" />
        	<div>
        		<h1>Wanderful Dreams</h1>
        		<h2>Discover the gems and wonders of this world</h2>
        	</div>
        </div>
    );
};

export default Banner;