import { useEffect } from "react";
import Paper from '@mui/material/Paper';
import CheckIcon from '@mui/icons-material/Check';
import ClearIcon from '@mui/icons-material/Clear';
import LinkTile from './LinkTile.js'

const QuizAnswer = (props) => {
    useEffect(() => {
        const script = document.createElement("script");
        script.src = "https://www.viator.com/orion/partner/widget.js";
        script.async = true;
        document.body.appendChild(script);
    });

    return (
        <Paper id="QuizAnswer">
            <div className="AnswerSectionHeading">
                <h2>{`Question ${props.question.number} of ${props.questionsTotal}`}</h2>
                {props.correct ?
                    <div className="correct"><CheckIcon size="large"/><h2>CORRECT</h2></div>
                    :
                    <div className="incorrect"><ClearIcon size="large"/><h2>INCORRECT</h2></div>
                }
            </div>
            <h3 className="AnswerText">{props.question.answer.answerText}</h3>
            <p>{props.question.answer.additionalText}</p>
            <div className="linksSection">
                {props.question.links.map(link=>(
                    <LinkTile link={link} key={link.url} campaign={`quiz${props.quizId}`}/>
                ))}
            </div>
        </Paper>
    );
};

export default QuizAnswer;