import { useEffect, useState } from "react";
import Paper from '@mui/material/Paper';
import IconButton from '@mui/material/IconButton';
import ExpandCircleDownIcon from '@mui/icons-material/ExpandCircleDown';

import ArticleTile from '../components/ArticleTile.js';

const ArticlesPage = (props) => {
    useEffect(() => {
        props.setTitle('All Articles');
        props.setMetaDescription(`This is a list of all our travel articles describing exciting activities, adventures and experiences you can take in amazing destinations around the world`);
    });

    const [maxTilesToDisplay, setMaxTilesToDisplay] = useState(8);

    return (
        <div id="ArticlesPage">
	        <Paper id="ArticlesSection">
                <div id="ArticlesSectionHeading">
                    <h2>All articles</h2>
                </div>
                <div id="ListOfArticlesSection">
                    {props.articles.slice(-Math.min(maxTilesToDisplay, props.articles.length)).reverse().map(article => (
                        <ArticleTile article={article} key={article.id}/>
                    ))}
                </div>
                {(props.articles.length > maxTilesToDisplay) && 
                    <div id="MoreButton">
                        <IconButton color="primary" aria-label="more" onClick={()=>setMaxTilesToDisplay(maxTilesToDisplay+8)}>
                            <ExpandCircleDownIcon fontSize="large" />
                        </IconButton>
                    </div>
                }
            </Paper>
        </div>
    );
};

export default ArticlesPage;