import { useEffect, useState } from "react";
import { styled, alpha } from '@mui/material/styles';
import { useParams, useNavigate } from 'react-router-dom';
import Paper from '@mui/material/Paper';
import IconButton from '@mui/material/IconButton';
import InputBase from '@mui/material/InputBase';
import InputAdornment from '@mui/material/InputAdornment';
import ExpandCircleDownIcon from '@mui/icons-material/ExpandCircleDown';
import SearchIcon from '@mui/icons-material/Search';

import QuizTile from '../components/QuizTile.js';
import ArticleTile from '../components/ArticleTile.js';

const Search = styled('div')(({ theme }) => ({
    position: 'relative',
    borderRadius: theme.shape.borderRadius,
    backgroundColor: alpha(theme.palette.common.white, 0.60),
    '&:hover': {
        backgroundColor: alpha(theme.palette.common.white, 0.90),
    },
    marginLeft: 0,
    width: '100%',
    [theme.breakpoints.up('sm')]: {
        marginLeft: theme.spacing(1),
        width: 'auto',
    },
}));

const StyledInputBase = styled(InputBase)(({ theme }) => ({
    color: theme.palette.primary.main,
    '& .MuiInputBase-input': {
        padding: theme.spacing(1, 1, 1, 1),
        transition: theme.transitions.create('width'),
        width: '100%',
        [theme.breakpoints.up('sm')]: {
            width: '24ch',
            '&:focus': {
                width: '28ch',
            },
        },
    },
}));

const SearchResultsPage = (props) => {
    useEffect(() => {
        props.setTitle(`Search Results: ${props.query}`);
        props.setMetaDescription(`This is a list of search results for travel quizzes and articles that match the search term: ${props.query}`);
    });

    const [maxTilesToDisplay, setMaxTilesToDisplay] = useState(8);
    let { searchQuery } = useParams();
    const navigate = useNavigate();

    useEffect(() => {
        props.setQuery(searchQuery);
    }, []);

    const handleSearch = (event) => {
        event.preventDefault();
        props.query && navigate(`/search-results/${props.query}`);
    };

    const articlesFound = props.articles.filter((article) => article.title.toUpperCase().includes(searchQuery.toUpperCase()) || article.tagline.toUpperCase().includes(searchQuery.toUpperCase())).map((article) => {return {...article, type: 'article'}});
    const quizzesFound = props.quizzes.filter((quiz) => quiz.title.toUpperCase().includes(searchQuery.toUpperCase()) || quiz.subtitle.toUpperCase().includes(searchQuery.toUpperCase())).map((quiz) => {return {...quiz, type: 'quiz'}});
    const results = articlesFound.concat(quizzesFound);

    return (
        <div id="SearchResultsPage">
	        <Paper id="SearchResultsSection">
                <div id="SearchResultsSectionHeading">
                    <h2>Search results</h2> 
                    <form onSubmit={handleSearch}>
    	                <Search>
    	                    <StyledInputBase
    	                        placeholder="Search quizzes and articles…"
                                value={props.query}
                                onChange={(event) => {
                                    props.setQuery(event.target.value);
                                }}
    	                        endAdornment={
    	                            <InputAdornment position="end">
	                                    <IconButton aria-label="search" color='primary' type="submit">
	                                        <SearchIcon />
	                                    </IconButton>
    	                            </InputAdornment>
    	                        }
    	                    />
    	                </Search>
                    </form>
	                <div></div>
                </div>
                <div id="ListOfSearchResultsSection">
                    {results.sort((a, b) => b.dateCode - a.dateCode).slice(-Math.min(maxTilesToDisplay, results.length)).map(result => {
                        return result.type === 'quiz' ?
                            <QuizTile quiz={result} key={`${result.type}${result.id}`}/>
                            :
                            <ArticleTile article={result} key={`${result.type}${result.id}`}/>
                    })}
                </div>
                {(results.length > maxTilesToDisplay) && 
                    <div id="MoreButton">
                        <IconButton color="primary" aria-label="more" onClick={()=>setMaxTilesToDisplay(maxTilesToDisplay+8)}>
                            <ExpandCircleDownIcon fontSize="large" />
                        </IconButton>
                    </div>
                }
                {(results.length === 0) &&
                    <p>No results found</p>
                }
            </Paper>
        </div>
    );
};

export default SearchResultsPage;