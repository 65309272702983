import { useEffect } from "react";
import { Link } from 'react-router-dom';
import Paper from '@mui/material/Paper';

const NoMatchPage = (props) => {
    useEffect(() => {
        props.setTitle('Page not found');
        props.setMetaDescription(`Page not found: please check the URL you have entered or go to the home page`);
    });

    return (
        <Paper id="NoMatchPage">
        	<h1>Page not found</h1>
    		<div id="textSection">
    			<p>Please check the URL you have entered or go to the home page <Link to="/">here</Link>.</p>
    		</div>
        </Paper>
    );
};

export default NoMatchPage;