import { useEffect } from "react";
import { Link } from 'react-router-dom';
import Paper from '@mui/material/Paper';
import Button from '@mui/material/Button';

import QuizTile from '../components/QuizTile.js';
import ArticleTile from '../components/ArticleTile.js';

const HomePage = (props) => {
    useEffect(() => {
        props.setTitle('Wanderful Dreams');
        props.setMetaDescription(`Wanderful Dreams is a site where people can discover the gems and wonders of this world by taking quizzes and reading articles`);
    });

    const homeStructuredData = {
        '@context': 'https://schema.org',
        '@type': 'WebSite',
        url: `https://wanderfuldreams.com`,
        potentialAction: {
            '@type': 'SearchAction',
            target: {
                '@type': 'EntryPoint',
                urlTemplate: `https://wanderfuldreams.com/search-results/{search_term_string}`,
            },
            'query-input': `required name=search_term_string`,
        }
    };

    return (
        <div id="HomePage">
            <script type="application/ld+json" dangerouslySetInnerHTML={{__html: JSON.stringify(homeStructuredData)}}></script>
	        <Paper id="QuizzesSection">
                <div id="QuizzesSectionHeading">
                    <h2>Latest quizzes</h2>
                    <Link to="/quizzes"><Button variant="text">See all</Button></Link>
                </div>
                <div id="LatestQuizzesSection">
                    {props.quizzes.slice(-4).reverse().map(quiz => (
                        <QuizTile quiz={quiz} key={quiz.id}/>
                    ))}
                </div>
            </Paper>

            <Paper id="ArticlesSection">
                <div id="ArticlesSectionHeading">
                    <h2>Latest articles</h2>
                    <Link to="/articles"><Button variant="text">See all</Button></Link>
                </div>
                <div id="LatestArticlesSection">
                    {props.articles.slice(-4).reverse().map(article => (
                        <ArticleTile article={article} key={article.id}/>
                    ))}
                </div>
            </Paper>
        </div>
    );
};

export default HomePage;