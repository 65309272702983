import { useState } from "react";
import Paper from '@mui/material/Paper';
import Button from '@mui/material/Button';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';


const QuizQuestion = (props) => {
    const [value, setValue] = useState('');

    const handleRadioChange = (event) => {
        setValue(event.target.value);
    };

    const handleSubmit = (event) => {
        event.preventDefault();

        props.setResponses({
            ...props.responses, 
            [props.question.number]: value,
        });
    };

    return (
        <Paper id="QuizQuestion">
            <div className="QuestionSectionHeading">
                <h2>{`Question ${props.question.number} of ${props.questionsTotal}`}</h2>
            </div>
            <div className="QuestionContent">
                <img src={require(`../resources/quiz_images/${props.quizId}/${props.question.questionImageFilename}`)} alt={`Visual for quiz question ${props.question.number}`}/>
                <div>
                    <h3 className="QuestionText">{props.question.questionText}</h3>
                    <form onSubmit={handleSubmit}>
                        <FormControl>
                            <FormLabel id="QuestionOptionsLabel">Your response:</FormLabel>
                            <RadioGroup aria-labelledby="QuestionOptionsLabel" name="QuestionOptions" value={value} onChange={handleRadioChange}>
                                {props.question.responseOptions.map(option => (
                                    <FormControlLabel value={option} control={<Radio />} label={option} key={option} />
                                ))}
                            </RadioGroup>
                            <Button type="submit" variant="contained">Submit</Button>
                        </FormControl>
                    </form>
                </div>
            </div>
        </Paper>
    );
};

export default QuizQuestion;