import { useEffect, useState } from "react";
import Paper from '@mui/material/Paper';
import IconButton from '@mui/material/IconButton';
import ExpandCircleDownIcon from '@mui/icons-material/ExpandCircleDown';

import QuizTile from '../components/QuizTile.js';

const QuizzesPage = (props) => {
    useEffect(() => {
        props.setTitle('All Quizzes');
        props.setMetaDescription(`This is a list of all our travel quizzes on exciting activities, adventures and experiences you can take in amazing destinations around the world`);
    });

    const [maxTilesToDisplay, setMaxTilesToDisplay] = useState(8);

    return (
        <div id="QuizzesPage">
	        <Paper id="QuizzesSection">
                <div id="QuizzesSectionHeading">
                    <h2>All quizzes</h2>
                </div>
                <div id="ListOfQuizzesSection">
                    {props.quizzes.slice(-Math.min(maxTilesToDisplay, props.quizzes.length)).reverse().map(quiz => (
                        <QuizTile quiz={quiz} key={quiz.id}/>
                    ))}
                </div>
                {(props.quizzes.length > maxTilesToDisplay) && 
                    <div id="MoreButton">
    	                <IconButton color="primary" aria-label="more" onClick={()=>setMaxTilesToDisplay(maxTilesToDisplay+8)}>
    						<ExpandCircleDownIcon fontSize="large" />
    					</IconButton>
                    </div>
                }
            </Paper>
        </div>
    );
};

export default QuizzesPage;