import { useEffect, useState } from "react";
import { useParams, Link } from 'react-router-dom';
import Paper from '@mui/material/Paper';
import Markdown from 'react-markdown';

import LinkTile from '../components/LinkTile.js';

const ArticlePage = (props) => {
	const [content, setContent] = useState('');
	let { articleTitle } = useParams();
	const article = props.articles.find(article => article.title.toString().replace(/\s+/g, '-').toLowerCase() === articleTitle);

	useEffect(() => {
		article && fetch(require(`../articles/${article.contentFilename}`))
			.then((res) => res.text())
			.then((text) => setContent(text));
	});

	useEffect(() => {
		article && props.setTitle(article.title);
		article && props.setMetaDescription(`Read this article about: ${article.title} - ${article.tagline}`);
	});

	if(article === undefined) return window.location.href = '/no-match';

	const datePublished = {
		year: article.dateCode.toString().slice(0,4),
		month: article.dateCode.toString().slice(4,6) - 1,
		day: article.dateCode.toString().slice(-2),
	}
	
	const articleStructuredData = {
	    '@context': 'https://schema.org',
	    '@type': 'Article',
	    headline: article.title,
	    description: article.tagline,
	    image: require(`../resources/article_images/${article.id}/${article.mainImageFilename}`),
	    dateModified: new Date(datePublished.year, datePublished.month, datePublished.day).toISOString(),
	    datePublished: new Date(datePublished.year, datePublished.month, datePublished.day).toISOString(),
	    author: {
	        '@type': 'Organization',
	        name: 'Wanderful Dreams',
	        url: 'https://wanderfuldreams.com/about',
	    },
	};

    return (
    	<div id="ArticlePage">
    		<script type="application/ld+json" dangerouslySetInnerHTML={{__html: JSON.stringify(articleStructuredData)}}></script>
	        <Paper id="ArticleSection">
	        	<h1>{article.title}</h1>
	        	<h4>{article.date}</h4>
	        	<h3>{article.tagline}</h3>
	    		<div id="imageSection">
	    			<img src={require(`../resources/article_images/${article.id}/${article.mainImageFilename}`)} alt={`${article.title}`}/>
				</div>
	        	<div id="ArticleContent">
	        		<div id="articleTextSection">
	        			<Markdown>{content}</Markdown>
	        		</div>
	        	</div>
	        </Paper>
			<Paper id="SeeMoreSection">
	        	<h1>More articles and quizzes</h1>
	        	<p>Check out our other <Link to="/quizzes">quizzes</Link> and <Link to="/articles">articles</Link>! Discover exciting destinations and experiences!</p>
	        	<p>{article.linkText}</p>
	    		<div id="linksSection">
                        {article.links.map(link=>(
                            <LinkTile link={link} key={link.url} campaign={`article${article.id}`}/>
                        ))}
				</div>
	        </Paper>
    	</div>
    );
};

export default ArticlePage;