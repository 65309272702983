import { useState, useEffect } from "react";
import { Routes, Route, Navigate } from "react-router-dom";
import { createTheme, ThemeProvider } from '@mui/material';

import './styles/App.scss';

import HomePage from './pages/HomePage.js';
import AboutPage from './pages/AboutPage.js';
import QuizzesPage from './pages/QuizzesPage.js';
import QuizPage from './pages/QuizPage.js';
import ArticlesPage from './pages/ArticlesPage.js';
import ArticlePage from './pages/ArticlePage.js';
import SearchResultsPage from './pages/SearchResultsPage.js';
import TermsOfUsePage from './pages/TermsOfUsePage.js';
import PrivacyPage from './pages/PrivacyPage.js';
import NoMatchPage from './pages/NoMatchPage.js';
import Header from './components/Header.js';
import Footer from './components/Footer.js';
import Banner from './components/Banner.js';
import SubscriptionBar from './components/SubscriptionBar.js';

import Articles from './articles/Articles.js';
import Quizzes from './quizzes/Quizzes.js';

const theme = createTheme({
    palette: {
        primary: {
            main: '#20458C',
        },
        secondary: {
            main: '#5472D3',
        },
    },
});

function App() {
    const [title, setTitle] = useState('Wanderful Dreams');
    const [metaDescription, setMetaDescription] = useState('Wanderful Dreams is a site where people can discover the gems and wonders of this world by taking quizzes and reading articles');
    const [query, setQuery] = useState('');

    useEffect(() => {
        document.title = title;
    }, [title]);

    useEffect(() => {
        let metaTag = document.querySelector('meta[name="description"]');
        metaTag.setAttribute("content", metaDescription);
    }, [metaDescription]);

    return (
    <ThemeProvider theme={theme}>
        <div className="App">
            <Header query={query} setQuery={setQuery}/>
            <div id="content">
                <Routes>
                    <Route path="/">
                        <Route index element={<><Banner /><SubscriptionBar /><HomePage quizzes={Quizzes} articles={Articles} setTitle={setTitle} setMetaDescription={setMetaDescription}/></>} />
                        <Route path="about" element={<><Banner /><SubscriptionBar /><AboutPage setTitle={setTitle} setMetaDescription={setMetaDescription}/></>} />
                        <Route path="quizzes" element={<><Banner /><SubscriptionBar /><QuizzesPage quizzes={Quizzes} setTitle={setTitle} setMetaDescription={setMetaDescription}/></>} />
                        <Route path="quizzes/:quizTitle" element={<><Banner /><SubscriptionBar /><QuizPage quizzes={Quizzes} setTitle={setTitle} setMetaDescription={setMetaDescription}/></>} />
                        <Route path="articles" element={<><Banner /><SubscriptionBar /><ArticlesPage articles={Articles} setTitle={setTitle} setMetaDescription={setMetaDescription}/></>} />
                        <Route path="articles/:articleTitle" element={<><Banner /><SubscriptionBar /><ArticlePage articles={Articles} setTitle={setTitle} setMetaDescription={setMetaDescription}/></>} />
                        <Route path="search-results/:searchQuery" element={<><Banner /><SubscriptionBar /><SearchResultsPage quizzes={Quizzes} articles={Articles} query={query} setQuery={setQuery} setTitle={setTitle} setMetaDescription={setMetaDescription}/></>} />
                        <Route path="terms-of-use" element={<TermsOfUsePage setTitle={setTitle} setMetaDescription={setMetaDescription}/>} />
                        <Route path="privacy" element={<PrivacyPage setTitle={setTitle} setMetaDescription={setMetaDescription}/>} />
                        <Route path="no-match" element={<NoMatchPage setTitle={setTitle} setMetaDescription={setMetaDescription} status={404}/>} />
                        <Route path="*" element={<Navigate to="/no-match"/>} />
                    </Route>
                </Routes>
            </div>
            <Footer/>
        </div>
    </ThemeProvider>
    );
}

export default App;
