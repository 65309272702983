import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import { Link } from 'react-router-dom';

const Footer = (props) => {
    return (
        <AppBar id="Footer" position="static" style={{ background: 'transparent', boxShadow: 'none'}}>
            <Toolbar id="footerToolbar">
                <Link to="/terms-of-use">Terms of use</Link>
                <Link to="/privacy">Privacy policy</Link>
            </Toolbar>
        </AppBar>
    );
};

export default Footer;