const Quizzes = [
	{
		id: 1,
		title: 'Best Observation Decks in New York City',
		dateCode: 20231128,
		subtitle: 'Catch an Eyeful',
		mainImageFilename: 'main.jpeg',
		contentFilename: '1.js',
	},
	{
		id: 2,
		title: 'Iconic Museum Displays in New York City',
		dateCode: 20231129,
		subtitle: 'Awe and Culture',
		mainImageFilename: 'main.jpg',
		contentFilename: '2.js',
	},
	{
		id: 3,
		title: 'Must-Eat Foods in New York City',
		dateCode: 20231130,
		subtitle: 'Indulge Your Tastebuds',
		mainImageFilename: 'main.jpg',
		contentFilename: '3.js',
	},
	{
		id: 4,
		title: 'Know Your Dim Sum in Hong Kong',
		dateCode: 20231201,
		subtitle: 'Yummy Yum Cha',
		mainImageFilename: 'main.jpg',
		contentFilename: '4.js',
	},
	{
		id: 5,
		title: 'Famous Artworks to Enjoy in Paris',
		dateCode: 20231204,
		subtitle: 'Classic Masterpieces',
		mainImageFilename: 'main.jpg',
		contentFilename: '5.js',
	},
	{
		id: 6,
		title: 'Beautiful Chateaux in France',
		dateCode: 20231205,
		subtitle: 'Stunning Castles',
		mainImageFilename: 'main.jpg',
		contentFilename: '6.js',
	},
	{
		id: 7,
		title: 'Know Your French Pastries in Paris',
		dateCode: 20231207,
		subtitle: 'Bon Appétit',
		mainImageFilename: 'main.jpg',
		contentFilename: '7.js',
	},
	{
		id: 8,
		title: 'Must-See Attractions in Milan',
		dateCode: 20231209,
		subtitle: 'Beautiful Buildings',
		mainImageFilename: 'main.jpg',
		contentFilename: '8.js',
	},
	{
		id: 9,
		title: 'Stunning Vistas of the Dolomites',
		dateCode: 20231211,
		subtitle: 'Panoramic Italian Alps',
		mainImageFilename: 'main.jpg',
		contentFilename: '9.js',
	},
	{
		id: 10,
		title: 'Grandiose Mega Casinos in Macau',
		dateCode: 20231212,
		subtitle: 'Upping the Ante',
		mainImageFilename: 'main.jpg',
		contentFilename: '10.js',
	},
	{
		id: 11,
		title: 'Epic Outlooks from the Sky in Dubai',
		dateCode: 20231214,
		subtitle: 'Spectacular Sights',
		mainImageFilename: 'main.jpg',
		contentFilename: '11.js',
	},
	{
		id: 12,
		title: 'Highlights of the Top Museums in London',
		dateCode: 20231217,
		subtitle: 'British Crown Jewels',
		mainImageFilename: 'main.jpg',
		contentFilename: '12.js',
	},
	{
		id: 13,
		title: 'Know Your Classic British Dishes in London',
		dateCode: 20231219,
		subtitle: 'Traditional and Hearty',
		mainImageFilename: 'main.jpg',
		contentFilename: '13.js',
	},
	{
		id: 14,
		title: 'Fairytale Castles of Germany',
		dateCode: 20231221,
		subtitle: 'Impressive and Magical',
		mainImageFilename: 'main.jpg',
		contentFilename: '14.js',
	},
	{
		id: 15,
		title: 'Know Your Bavarian Dishes in Germany',
		dateCode: 20231226,
		subtitle: 'Hearty and Meaty Food',
		mainImageFilename: 'main.jpg',
		contentFilename: '15.js',
	},
	{
		id: 16,
		title: 'Newport Mansions of Rhode Island',
		dateCode: 20231228,
		subtitle: 'Gilded Age Opulence',
		mainImageFilename: 'main.jpg',
		contentFilename: '16.js',
	},
	{
		id: 17,
		title: 'Glorious Churches in Rome',
		dateCode: 20231230,
		subtitle: 'Heavenly and Awe-Inspiring',
		mainImageFilename: 'main.jpg',
		contentFilename: '17.js',
	},
	{
		id: 18,
		title: 'Best Desserts in Italy',
		dateCode: 20240103,
		subtitle: 'Decadent Treats',
		mainImageFilename: 'main.jpg',
		contentFilename: '18.js',
	},
	{
		id: 19,
		title: 'Attractions for Space Lovers in the US',
		dateCode: 20240105,
		subtitle: 'Out-of-this-World',
		mainImageFilename: 'main.jpg',
		contentFilename: '19.js',
	},
	{
		id: 20,
		title: 'Know Your Traditional Dishes in Japan',
		dateCode: 20240109,
		subtitle: 'Centuries of Refinement',
		mainImageFilename: 'main.jpg',
		contentFilename: '20.js',
	},
	{
		id: 21,
		title: 'Noble Samurai in Japan',
		dateCode: 20240114,
		subtitle: 'Elite Warrior Class',
		mainImageFilename: 'main.jpg',
		contentFilename: '21.js',
	},
	{
		id: 22,
		title: 'Best Beaches in the Cayman Islands',
		dateCode: 20240117,
		subtitle: 'Fine Sand and Turquoise Waters',
		mainImageFilename: 'main.jpg',
		contentFilename: '22.js',
	},
	{
		id: 23,
		title: 'Famous Chocolate Brands in Switzerland',
		dateCode: 20240123,
		subtitle: 'Deliciously Smooth',
		mainImageFilename: 'main.jpg',
		contentFilename: '23.js',
	},
	{
		id: 24,
		title: 'Know Your Pasta Dishes in Italy',
		dateCode: 20240125,
		subtitle: 'Quintessential Italian Cuisine',
		mainImageFilename: 'main.jpg',
		contentFilename: '24.js',
	},
	{
		id: 25,
		title: 'Luxury Fashion Brands in Italy',
		dateCode: 20240130,
		subtitle: 'Italian Style',
		mainImageFilename: 'main.jpg',
		contentFilename: '25.js',
	},
	{
		id: 26,
		title: 'Westminster Winning Dog Breeds in New York',
		dateCode: 20240202,
		subtitle: 'Best in Show',
		mainImageFilename: 'main.jpg',
		contentFilename: '26.js',
	},
	{
		id: 27,
		title: 'Sports Car History in Italy',
		dateCode: 20240206,
		subtitle: 'Italian speed',
		mainImageFilename: 'main.jpg',
		contentFilename: '27.js',
	},
];

export default Quizzes;