import { Link } from 'react-router-dom';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import { CardActionArea } from '@mui/material';

const ArticleTile = (props) => {
    return (
        <Card id="ArticleTile">
            <Link to={`/articles/${props.article.title.toString().replace(/\s+/g, '-').toLowerCase()}`} style={{ textDecoration: 'none' }}>
                <CardActionArea>
                    <CardMedia
                        component="img"
                        height="140"
                        src={require(`../resources/article_images/${props.article.id}/${props.article.mainImageFilename}`)}
                        alt="image"
                    />
                    <CardContent>
                        <h4>{props.article.title}</h4>
                    </CardContent>
                </CardActionArea>
            </Link>
        </Card>
    );
};

export default ArticleTile;