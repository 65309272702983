import { useState } from "react";

import Paper from '@mui/material/Paper';
import Button from '@mui/material/Button';
import { styled } from '@mui/material/styles';
import InputBase from '@mui/material/InputBase';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';

const EmailInput = styled('div')(({ theme }) => ({
    position: 'relative',
    borderRadius: theme.shape.borderRadius,
    backgroundColor: theme.palette.common.white,
    marginRight: '10px',
    width: '100%',
    [theme.breakpoints.up('sm')]: {
        marginLeft: theme.spacing(1),
        width: 'auto',
    },
}));

const EmailInputBase = styled(InputBase)(({ theme }) => ({
    color: theme.palette.primary.main,
    '& .MuiInputBase-input': {
        padding: theme.spacing(1, 1, 1, 1),
        transition: theme.transitions.create('width'),
        width: '100%',
        [theme.breakpoints.up('sm')]: {
            width: '24ch',
            '&:focus': {
                width: '28ch',
            },
        },
    },
}));

function validateEmail(email) {
    var re = /\S+@\S+\.\S+/;
    return re.test(email);
}

const SubscriptionBar = (props) => {
    const [open, setOpen] = useState(false);
    const [openAlert, setOpenAlert] = useState(false);
    const [email, setEmail] = useState('');

    const handleClickOpen = async () => {
        if(!validateEmail(email)) {
            setOpenAlert(true);
            return;
        }

        const response = await fetch('/api/email-subscription', {
            method: "POST",
            headers: {
                'Content-Type': 'text/plain',
            },
            body: email,
        });
        const result = await response.text();
        if(result === 'SUCCESS') setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    return (
        <Paper id="SubscriptionBar">
        	<p className="webOnly">Subscribe for the latest quizzes and articles:</p>
            <p className="mobileOnly">Subscribe:&nbsp;</p>
			<EmailInput>
				<EmailInputBase
					placeholder="Email address"
                    value={email}
                    onChange={(event) => {
                        setEmail(event.target.value);
                    }}
					inputProps={{ 'aria-label': 'email address' }}
				/>
			</EmailInput>
        	<span className="webOnly"><Button variant="contained" color="success" onClick={handleClickOpen}>Subscribe</Button></span>
            <span className="mobileOnly"><Button variant="contained" color="success" onClick={handleClickOpen}>OK</Button></span>
            <Dialog id="SubscriptionDialog" open={open} onClose={handleClose} aria-labelledby="dialog-title" aria-describedby="dialog-description">
                <DialogTitle id="SubscriptionDialogTitle">{"Subscribed!"}</DialogTitle>
                <DialogContent>
                    <DialogContentText id="SubscriptionDialogDescription">
                        Congratulations on subscribing to updates from Wanderful Dreams. We will email you whenever we release a new quiz or article.
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose}>OK</Button>
                </DialogActions>
            </Dialog>
            <Snackbar
                anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
                open={openAlert}
                autoHideDuration={6000}
                onClose={()=>setOpenAlert(false)}
            >
                <Alert severity="error">Invalid email address</Alert>
            </Snackbar>
        </Paper>
    );
};

export default SubscriptionBar;