import { useEffect, useState } from "react";
import jumboPic from '../resources/jumboPic2.png';
import Paper from '@mui/material/Paper';
import Markdown from 'react-markdown';
import aboutMarkdown from '../markdown/About.md';

const AboutPage = (props) => {
	const [content, setContent] = useState('');

	useEffect(() => {
		props.setTitle('About Wanderful Dreams');
		props.setMetaDescription('Wanderful Dreams is a site dedicated to helping you discover amazing travel destinations and experiences');
	});

	useEffect(() => {
		fetch(aboutMarkdown)
			.then((res) => res.text())
			.then((text) => setContent(text));
	});

    return (
        <Paper id="AboutPage">
        	<h1>About Wanderful Dreams</h1>
        	<div id="AboutContent">
        		<div id="imageSection" className="mobileOnly">
        			<img src={jumboPic} alt="Hot air balloon floating through a dreamy landscape"/>
    			</div>
        		<div id="textSection">
        			<Markdown>{content}</Markdown>
        		</div>
        		<div id="imageSection" className="webOnly">
        			<img src={jumboPic} alt="Hot air balloon floating through a dreamy landscape"/>
    			</div>
        	</div>
        </Paper>
    );
};

export default AboutPage;